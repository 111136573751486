import { ofType } from "redux-observable";
import { map, mergeMap, catchError, delay } from "rxjs/operators";
import {
  setCustomer,
  setCustomerError,
  GET_CUSTOMER,
} from "../actions/customerActions";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { url } from "../config";

const getCustomerEpic = (action$, state$) =>
  action$.pipe(
    ofType(GET_CUSTOMER),
    delay(1000),
    mergeMap((action) =>
      ajax({
        url: `${url}/customer/getUserData/${action.payload.ssid}`,
        method: "GET",
        headers: { Authorization: `Bearer ${action.payload.token}` },
      }).pipe(
        map((response) => setCustomer(response.response)),
        catchError((error) => {
          return of(setCustomerError(error));
        })
      )
    )
  );

export { getCustomerEpic };
