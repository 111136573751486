const { CLEAR_CUSTOMER, GET_CUSTOMER, SET_CUSTOMER, SET_CUSTOMER_ERROR } = require("../actions/customerActions")

const initialState = {
  customer: null,
  isLoading: false,
  error: false,
}
const customerReducer = (state = initialState, {type, payload}) => {
  switch(type){

    case CLEAR_CUSTOMER:
        return {...state, customer: null, isLoading: false, error: false}

    case GET_CUSTOMER:
        return {...state, customer: null, isLoading: true, error: false}

    case SET_CUSTOMER:
        return {...state, customer: payload, isLoading: false, error: false}

    case SET_CUSTOMER_ERROR:
        return {...state, customer: null, isLoading: false, error: true}

    default:
      return state
  }
}

export const customerSelectors = {
    customer: state => state.customer.customer,
    isLoading: state => state.customer.isLoading,
    error: state => state.customer.error,
}

export default customerReducer
