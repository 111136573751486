import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { kpiSelectors } from '../../redux/reducers/kpiReducer';
import { VerticalBarChart } from '../charts/VerticalBarChart';

const LeaderboardCitiesContainer = (props) => {
	const salesByCity = useSelector(kpiSelectors.salesByCity);
	const [cities, setCities] = useState([]);
	const [sales, setSales] = useState([]);
	useEffect(() => {
		const cityArray = salesByCity.map((item) => item.city);
		const salesArray = salesByCity.map((item) => item.sales);
		setCities(cityArray);
		setSales(salesArray);
	}, [salesByCity]);

	return (
		<div style={style.Wrapper}>
			<div style={style.LeaderboardTitleContainer}>
				<p style={style.LeaderboardCityText}>Leaderboard städer</p>
			</div>
			<VerticalBarChart cities={cities} sales={sales} />
		</div>
	);
};

const style = {
	Wrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: '700px',
		height: '1000px',
		alignItems: 'center',
	},
	LeaderboardTitleContainer: {
		marginBottom: '40px',
		width: '200px',
	},
	LeaderboardCityText: {
		fontFamily: 'Inter',
		fontSize: '28px',
		textAlign: 'center',
	},
};

export default LeaderboardCitiesContainer;
