const { LOGIN_ADMIN, SET_ADMIN_USER, SET_ADMIN_USER_ERROR, CLEAR_ADMIN_USER } = require("../actions/userActions")

const initialState = {
  adminUser: null,
  isLoading: false,
  error: false,
}
const userReducer = (state = initialState, {type, payload}) => {
  switch(type){

    case LOGIN_ADMIN:
        return {...state, adminUser: null, isLoading: true, error: false}

    case SET_ADMIN_USER:
        return {...state, adminUser: payload, isLoading: false, error: false}

    case SET_ADMIN_USER_ERROR:
      return {...state, adminUser: null, isLoading: false, error: true}

    case CLEAR_ADMIN_USER:
      return {...state, adminUser: null, isLoading: false, error: false}

    default:
      return state
  }
}

export const userSelectors = {
    adminUser: state => state.user.adminUser,
    isLoading: state => state.user.isLoading,
    error: state => state.user.error,
}

export default userReducer
