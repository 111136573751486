import { createStore, combineReducers, applyMiddleware } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { compose } from 'redux';
import { getCharitiesEpic } from './middleware/charityEpics';
import { getCustomerEpic } from './middleware/customerEpics';
import { setDealEpic } from './middleware/dealEpics';
import { loginAdminEpic } from './middleware/userEpics';

import charityReducer from './reducers/charityReducer';
import customerReducer from './reducers/customerReducer';
import dealReducer from './reducers/dealReducer';
import userReducer from './reducers/userReducer';
import kpiReducer from './reducers/kpiReducer';
import { getKPIEpic } from './middleware/kpiEpics';

const rootReducer = combineReducers({
	charity: charityReducer,
	customer: customerReducer,
	deal: dealReducer,
	user: userReducer,
	kpi: kpiReducer,
});

const rootEpic = combineEpics(
	getCharitiesEpic,
	getCustomerEpic,
	setDealEpic,
	loginAdminEpic,
	getKPIEpic,
);

const configureStore = () => {
	const epicMiddleware = createEpicMiddleware({
		dependencies: {},
	});

	const composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const store = createStore(
		rootReducer,
		composeEnhancers(applyMiddleware(epicMiddleware)),
	);

	epicMiddleware.run(rootEpic);
	return store;
};

export default configureStore;
