const CLEAR_CUSTOMER = "CLEAR_CUSTOMER"
const clearCustomer = () => {
  return {
    type: CLEAR_CUSTOMER
  }
}
export {clearCustomer, CLEAR_CUSTOMER}

const GET_CUSTOMER = "GET_CUSTOMER"
const getCustomer = (ssid, token) => {
  return {
    type: GET_CUSTOMER,
    payload: {token: token, ssid: ssid}
  }
}
export {getCustomer, GET_CUSTOMER}

const SET_CUSTOMER = "SET_CUSTOMER"
const setCustomer = (Customer) => {
  return {
    type: SET_CUSTOMER,
    payload: Customer
  }
}
export {setCustomer, SET_CUSTOMER}

const SET_CUSTOMER_ERROR = "SET_CUSTOMER_ERROR"
const setCustomerError = () => {
  return {
    type: SET_CUSTOMER_ERROR
  }
}
export {setCustomerError, SET_CUSTOMER_ERROR}