import React from "react";
import VersionDependantStyles from "../../components/containers/VersionDependantStyles";

function PageNotFound(props) {
  return (
    <VersionDependantStyles newVersion={false}>
      <div className="App">
        <div className="Container">
          <p>Oops, sidan finns inte...</p>
        </div>
      </div>
    </VersionDependantStyles>
  );
}

export default PageNotFound;
