import React from "react";

function OptionsButton(props) {
  return (
    <button
      className="Button"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export default OptionsButton;
