import { ofType } from 'redux-observable';
import { map, delay, mergeMap, catchError } from 'rxjs/operators';
import {
    setDeal,
    setDealError,
    SEND_DEAL
} from '../actions/dealActions'
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { url } from '../config'

const setDealEpic = (action$, state$) =>
  action$.pipe(
    ofType(SEND_DEAL),
    delay(1000),
    mergeMap((action) =>
      ajax({
        url: `${url}/contract/createCase`,
        body: action.payload.deal,
        method: 'POST',
        headers: {'Authorization': `Bearer ${action.payload.token}` }
      }).pipe(
        map((response) => setDeal(response.response)),
        catchError((error) => {
          return of(setDealError(error));
        })
      )
    )
  );

export { setDealEpic };

