import React, { useEffect, useState } from 'react';

export const HorizontalBarChart = (props) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		setData(props.data);
	}, [props.data]);

	const getBarWidth = (item, index) => {
		const maxCount = Math.max(...data.map((item) => item.count));
		const screenWidth = window.innerWidth;
		const maxWidth = Math.min(950, screenWidth * 0.95);
		const minWidth = Math.min(450, screenWidth * 0.47);
		const widthStep = (maxWidth - minWidth) / (maxCount - 1);
		const width = minWidth + (item.count - 1) * widthStep;
		return `${width}px`;
	};

	return (
		<div style={style.Wrapper}>
			{data &&
				data.slice(0, 10).map((item, index) => (
					<div
						key={index}
						style={{
							...style.Bar,
							width: getBarWidth(item, index),
						}}
					>
						<div style={style.PlacementNumberContainer}>
							<p style={style.PlacementNumberText}>{index + 1}</p>
						</div>
						<div style={style.NameContainer}>
							<p style={style.NameText}>{item.volunteerName}</p>
						</div>
						<div style={style.SalesContainer}>
							<p style={style.SalesText}>{item.count}</p>
						</div>
					</div>
				))}
		</div>
	);
};

const style = {
	Wrapper: {
		width: '100%',
	},
	Bar: {
		display: 'flex',
		alignItems: 'center',
		height: `80px`,
		backgroundColor: 'rgba(217, 217, 217, 1)',
		marginBottom: '20px',
	},
	PlacementNumberContainer: {
		width: '50px',
		marginLeft: '50px',
	},
	PlacementNumberText: {
		color: '#686868',
		textAlign: 'left',
	},
	NameContainer: {
		width: '250px',
	},
	NameText: {
		color: '#686868',
	},
	SalesContainer: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
	},
	SalesText: {
		color: '#686868',
		textAlign: 'left',
		marginRight: '50px',
	},
};
