import { ofType } from "redux-observable";
import { map, delay, mergeMap, catchError, tap } from "rxjs/operators";
import { setKPI, setKPIError, GET_KPI } from "../actions/kpiActions.js";
import { ajax } from "rxjs/ajax";
import { of, forkJoin } from "rxjs";
import { url } from "../config";

const getKPIEpic = (action$, state$) => {
  return action$.pipe(
    ofType(GET_KPI),
    mergeMap((action) =>
      forkJoin([
        ajax({
          url: `${url}/kpi/leaderboardVolunteers`,
          headers: action.payload,
          method: "GET",
        }),
        ajax({
          url: `${url}/kpi/leaderboardCities`,
          headers: action.payload,
          method: "GET",
        }),
        ajax({
          url: `${url}/kpi/weeklySales`,
          headers: action.payload,
          method: "GET",
        }).pipe(
          map((response) => ({
            ...response,
            response: {
              ...response.response,
              volunteerWeeklySalesCount: response.response.volunteerSalesCount,
            },
          }))
        ),
        ajax({
          url: `${url}/kpi/monthlySales`,
          headers: action.payload,
          method: "GET",
        }),
      ]).pipe(
        map(([response1, response2, response3, response4]) => {
          const mergedResponse = {
            salesByVolunteer: response1.response.salesByVolunteer,
            salesByCity: response2.response.salesByCity,
            volunteerWeeklySalesCount:
              response3.response.volunteerWeeklySalesCount,
            leftUntilWeeklyGoalIsReached:
              response3.response.leftUntilWeeklyGoalIsReached,
            leftUntilMonthlyGoalIsReached:
              response4.response.leftUntilMonthlyGoalIsReached,
            averageSales: response4.response.averageSales,
            volunteerMonthlySalesCount: response4.response.volunteerSalesCount,
            volunteerName: response4.response.volunteerName,
          };
          return setKPI(mergedResponse);
        }),
        catchError((error) => {
          return of(setKPIError(error));
        })
      )
    )
  );
};

export { getKPIEpic };
