const {
  GET_KPI,
  SET_KPI,
  SET_KPI_ERROR,
  CLEAR_KPI,
} = require("../actions/kpiActions");

const initialState = {
  volunteerMonthlySales: 0,
  volunteerWeeklySales: 0,
  averageSales: 0,
  leftUntilWeeklyGoalIsReached: 0,
  leftUntilMonthlyGoalIsReached: 0,
  salesByCity: [],
  salesByVolunteer: [],
  isLoading: false,
  error: false,
};
const kpiReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_KPI:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case SET_KPI:
      return {
        ...state,
        isLoading: false,
        error: false,
        volunteerMonthlySales: payload.volunteerMonthlySalesCount,
        volunteerWeeklySales: payload.volunteerWeeklySalesCount,
        averageSales: payload.averageSales,
        volunteerName: payload.volunteerName,
        leftUntilWeeklyGoalIsReached: payload.leftUntilWeeklyGoalIsReached,
        leftUntilMonthlyGoalIsReached: payload.leftUntilMonthlyGoalIsReached,
        salesByCity: payload.salesByCity,
        salesByVolunteer: payload.salesByVolunteer,
      };

    case SET_KPI_ERROR:
      return { ...state, isLoading: false, error: true };

    case CLEAR_KPI:
      return initialState;

    default:
      return state;
  }
};

export const kpiSelectors = {
  volunteerMonthlySales: (state) => state.kpi.volunteerMonthlySales,
  volunteerWeeklySales: (state) => state.kpi.volunteerWeeklySales,
  averageSales: (state) => state.kpi.averageSales,
  volunteerName: (state) => state.kpi.volunteerName,
  leftUntilWeeklyGoalIsReached: (state) =>
    state.kpi.leftUntilWeeklyGoalIsReached,
  leftUntilMonthlyGoalIsReached: (state) =>
    state.kpi.leftUntilMonthlyGoalIsReached,
  salesByCity: (state) => state.kpi.salesByCity,
  salesByVolunteer: (state) => state.kpi.salesByVolunteer,
  isLoading: (state) => state.kpi.isLoading,
  error: (state) => state.kpi.error,
};

export default kpiReducer;
