const LOGIN_ADMIN = "LOGIN_ADMIN"
const loginAdmin = (loginDetails) => {
  return {
    type: LOGIN_ADMIN,
    payload: loginDetails
  }
}
export {loginAdmin, LOGIN_ADMIN}

const SET_ADMIN_USER = "SET_ADMIN_USER"
const setAdminUser = (userDetails) => {
  localStorage.setItem("jwt", userDetails.jwt)
  localStorage.setItem("charity", JSON.stringify({...userDetails.user.charity, email: userDetails.user.email, time: new Date()}))
  return {
    type: SET_ADMIN_USER,
    payload: userDetails.user
  }
}
export {setAdminUser, SET_ADMIN_USER}

const SET_ADMIN_USER_ERROR = "SET_ADMIN_USER_ERROR"
const setAdminUserError = (error) => {
  return {
    type: SET_ADMIN_USER_ERROR,
    payload: error
  }
}
export {setAdminUserError, SET_ADMIN_USER_ERROR}

const CLEAR_ADMIN_USER = "CLEAR_ADMIN_USER"
const clearAdminUser = () => {
  return {
    type: CLEAR_ADMIN_USER
  }
}
export {clearAdminUser, CLEAR_ADMIN_USER}