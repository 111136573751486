import logo from "./logo.svg";
import { Router } from "@reach/router";
import NewSelectCharity from "./pages/newVersion/SelectCharity";
import NewFinishedScreen from "./pages/newVersion/FinishedScreen";
import NewStatusPage from "./pages/newVersion/StatusPage";
import NewEnterDetails from "./pages/newVersion/EnterDetails/EnterDetails";
import PageNotFound from "./pages/newVersion/PageNotFound";
import NewDashboardPage from "./pages/newVersion/DashboardPage";

import SelectCharity from "./pages/SelectCharity";
import FinishedScreen from "./pages/FinishedScreen";
import EnterDetails from "./pages/EnterDetails";
import DashboardPage from "./pages/DashboardPage";

function App() {
  return (
    <div className="App">
      <Router style={{ height: "100%" }}>
        {/* 
          Due to legacy styles and legacy components needing to be 
          kept, it will be separated by the new version via a slug.
          The legacy version should entirely be contained within
          the "/*" path, but the new should always be prefixed by a slug,
          like so: "/:slug/*". This will determine which styles and components
          that are to be used.

          When legacy is to be removed the legacy code base should be safe to
          delete, and the `VersionBasedStyles` component should not have to be
          rendered anymore, although it might be useful for upcoming releases.
          If you are unsure if the component is legacy, there should always
          be a `newVersion` equivalent of the component, and if there is,
          this means that the one not in the `newVersion` folder is legacy
          and should be safe to delete. Some files are also prefixed with
          the `legacy` keyword, such as the `legacy-App.css` file.
        */}
        {/* LEGACY */}
        <SelectCharity path="/" />
        <DashboardPage path="/dashboard" />
        <EnterDetails path="/details" />
        <FinishedScreen path="/done" />
        {/* NEW */}
        <NewSelectCharity path="/:slug" />
        <NewStatusPage path="/:slug/status" />
        <NewDashboardPage path="/:slug/dashboard" />
        <NewEnterDetails path="/:slug/details" />
        <NewFinishedScreen path="/:slug/done" />
        <PageNotFound default />
      </Router>
    </div>
  );
}

export default App;
