const GET_KPI = 'GET_KPI';
const getKPI = (kpiDetails) => {
	return {
		type: GET_KPI,
		payload: kpiDetails,
	};
};
export { getKPI, GET_KPI };

const SET_KPI = 'SET_KPI';
const setKPI = (kpiDetails) => {
	return {
		type: SET_KPI,
		payload: kpiDetails,
	};
};
export { setKPI, SET_KPI };

const SET_KPI_ERROR = 'SET_KPI_ERROR';
const setKPIError = (error) => {
	return {
		type: SET_KPI_ERROR,
		payload: error,
	};
};
export { setKPIError, SET_KPI_ERROR };

const CLEAR_KPI = 'CLEAR_KPI';
const clearKPI = () => {
	return {
		type: CLEAR_KPI,
	};
};
export { clearKPI, CLEAR_KPI };
