import getSortingCodeInfo from "./banks.js";
import { KontonummerError } from "./errors.js";
import validateCheckDigit, { mod10 } from "./validate.js";
import formatter from "./format.js";

// TODO: This class and the whole bankAccountValidation folder is based on the npm package "kontonummer"
// (https://www.npmjs.com/package/kontonummer) but due to importing issues (most likely due to
// outdated packages and node) I imported it statically and converted the files from TS to JS.
// This might cause trouble in the future due to it not being maintained, so best would be to
// try to fix these issues, but this solution will do for now.

export default class Kontonummer {
  #bankName;
  #sortingCode;
  #accountNumber;
  #type;
  #comment;
  #valid;

  get bankName() {
    return this.#bankName;
  }
  get sortingCode() {
    return this.#sortingCode;
  }
  get accountNumber() {
    return this.#accountNumber;
  }
  get type() {
    return this.#type;
  }
  get comment() {
    return this.#comment;
  }
  get valid() {
    return this.#valid;
  }

  constructor(
    sortingCodeWithOrWithoutAccountNumber,
    accountOrOptions,
    optionsArg
  ) {
    let accountNumber;
    let options = {
      mode: "strict",
    };

    // parse params
    // sortingCode
    sortingCodeWithOrWithoutAccountNumber =
      `${sortingCodeWithOrWithoutAccountNumber}`.replace(/[^\d]/g, "");
    // Swedbank 8xxx-x have 5 digits
    const sortingCode = sortingCodeWithOrWithoutAccountNumber.substring(
      0,
      sortingCodeWithOrWithoutAccountNumber.startsWith("8") ? 5 : 4
    );

    // accountNumber
    if (typeof accountOrOptions === "object") {
      options = accountOrOptions;
      accountNumber = sortingCodeWithOrWithoutAccountNumber.substring(
        sortingCodeWithOrWithoutAccountNumber.startsWith("8") ? 5 : 4
      );
    } else if (
      typeof accountOrOptions === "string" ||
      typeof accountOrOptions === "number"
    ) {
      accountNumber = `${accountOrOptions}`.replace(/[^\d]/g, "");
    } else {
      accountNumber = sortingCodeWithOrWithoutAccountNumber.substring(
        sortingCodeWithOrWithoutAccountNumber.startsWith("8") ? 5 : 4
      );
    }

    // optionsArg
    if (typeof optionsArg === "object") {
      options = optionsArg;
    }

    // validate arguments
    if (
      sortingCode.length < 4 ||
      (sortingCode.length > 4 ? !mod10(sortingCode) : false)
    ) {
      throw new KontonummerError("Invalid sorting code");
    }

    if (accountNumber.length < 2) {
      throw new KontonummerError("Invalid account number");
    }

    const bank = Kontonummer.getSortingCodeInfo(sortingCode);

    const valid = validateCheckDigit(
      bank.type,
      bank.comment,
      sortingCode,
      accountNumber
    );

    if (!valid && options.mode === "strict")
      throw new KontonummerError("Invalid account number");
    if (!valid && bank.type === 1 && options.mode === "semi")
      throw new KontonummerError("Invalid account number");

    this.#bankName = bank.bankName;
    this.#type = bank.type;
    this.#comment = bank.comment;

    this.#sortingCode = sortingCode;
    this.#accountNumber = accountNumber;
    this.#valid = valid;
  }

  format(format) {
    return formatter(
      this.sortingCode,
      this.accountNumber,
      Kontonummer.getSortingCodeInfo(this.sortingCode),
      format
    );
  }

  static parse(
    sortingCodeWithOrWithoutAccountNumber,
    accountOrOptions,
    options
  ) {
    if (
      typeof accountOrOptions === "string" ||
      typeof accountOrOptions === "number"
    )
      return new Kontonummer(
        sortingCodeWithOrWithoutAccountNumber,
        accountOrOptions,
        options
      );
    else
      return new Kontonummer(
        sortingCodeWithOrWithoutAccountNumber,
        accountOrOptions
      );
  }

  static valid(sortingCodeWithOrWithoutAccountNumber, accountNumber) {
    if (
      accountNumber &&
      (typeof accountNumber !== "string" || typeof accountNumber !== "number")
    )
      throw new KontonummerError(
        "Kontonummer.valid() does not accept an options argument"
      );
    try {
      if (accountNumber)
        new Kontonummer(sortingCodeWithOrWithoutAccountNumber, accountNumber);
      // eslint-disable-line no-new
      else new Kontonummer(sortingCodeWithOrWithoutAccountNumber); // eslint-disable-line no-new
      return true;
    } catch {
      return false;
    }
  }

  static getSortingCodeInfo(sortingCode) {
    const bank = getSortingCodeInfo(sortingCode);
    if (typeof bank === "undefined")
      throw new KontonummerError(
        `No Bank found with sorting code ${sortingCode}`
      );
    return bank;
  }

  toJSON() {
    return {
      bankName: this.bankName,
      sortingCode: this.sortingCode,
      accountNumber: this.accountNumber,
      type: this.type,
      comment: this.comment,
      valid: this.valid,
    };
  }

  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.toJSON();
  }
}

export const parse = Kontonummer.parse;
export const valid = Kontonummer.valid;
