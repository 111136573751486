import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { customerSelectors } from "../../redux/reducers/customerReducer";
import { dealSelectors } from "../../redux/reducers/dealReducer";
import { userSelectors } from "../../redux/reducers/userReducer";
import { resetCharity } from "../../redux/actions/charityActions";
import {
  getCustomer,
  clearCustomer,
} from "../../redux/actions/customerActions";
import { sendDeal, clearDeal } from "../../redux/actions/dealActions";
import Button from "../../components/buttons/optionsButton";
import { url } from "../../redux/config";
import DetailsContainer from "../../components/containers/DetailsContainer/newVersion/DetailsContainer";
import Header from "../../components/general/newVersion/Header";
import {
  Calendar30DaysIcon,
  MonthlyAverageIcon,
  WeeklySalesIcon,
} from "../../ui/assets/icons";
import { kpiSelectors } from "../../redux/reducers/kpiReducer";
import PieChartContainer from "../../components/containers/PieChartContainer";
import LeaderboardCitiesContainer from "../../components/containers/LeaderboardCitiesContainer";
import LeaderboardboardVolunteersContainer from "../../components/containers/LeaderboardboardVolunteersContainer";
import { clearKPI, getKPI } from "../../redux/actions/kpiActions";
import CharityStyles from "../../components/containers/CharityStyles";
import VersionDependantStyles from "../../components/containers/VersionDependantStyles";
import NavBar from "../../components/general/NavBar";

const DashboardPage = (props) => {
  const volunteerMonthlySales = useSelector(kpiSelectors.volunteerMonthlySales);
  const volunteerWeeklySales = useSelector(kpiSelectors.volunteerWeeklySales);
  const averageSales = useSelector(kpiSelectors.averageSales);
  const leftUntilWeeklyGoalIsReached = useSelector(
    kpiSelectors.leftUntilWeeklyGoalIsReached
  );
  const leftUntilMonthlyGoalIsReached = useSelector(
    kpiSelectors.leftUntilMonthlyGoalIsReached
  );
  const volunteerName = useSelector(kpiSelectors.volunteerName);

  const PieChartData = [
    {
      labels: ["Uppnått", "Oupnått"],
      label: "Veckomål",
      data: [volunteerWeeklySales, leftUntilWeeklyGoalIsReached],
      backgroundColor: ["rgba(99, 146, 52, 1)", "rgba(217, 217, 217, 1)"],
      borderColor: ["rgba(145, 221, 68, 1)", "rgba(217, 217, 217, 0)"],
      borderWidth: 3,
    },
    {
      labels: ["Uppnått", "Oupnått"],
      label: "Månadsmål",
      data: [volunteerMonthlySales, leftUntilMonthlyGoalIsReached],
      backgroundColor: ["rgba(62, 131, 170, 1)", "rgba(217, 217, 217, 1)"],
      borderColor: ["rgba(145, 221, 68, 1)", "rgba(217, 217, 217, 0)"],
      borderWidth: 3,
    },
  ];

  useEffect(() => {
    const jwt = `Bearer ${localStorage.getItem("jwt")}`;
    const volunteerId = localStorage.getItem("volunteerId") || 0;
    const payload = {
      Authorization: jwt,
      "x-volunteer-id": volunteerId,
    };
    props.getKPI(payload);
  }, []);

  return (
    <VersionDependantStyles newVersion={true}>
      <div className="Wrapper">
        <Header />
        <NavBar
          navigate={props.navigate}
          clearCustomer={props.clearCustomer}
          resetCharity={props.resetCharity}
          clearKPI={props.clearKPI}
          slug={props.slug}
          volunteerName={volunteerName}
          chosenTab="dashboard"
        />
        <div className="Container">
          <div style={style.DetailsContainer}>
            <DetailsContainer
              icon={Calendar30DaysIcon}
              middleText={volunteerMonthlySales}
              bottomText={"Antal sälj månad"}
            />
            <DetailsContainer
              icon={MonthlyAverageIcon}
              middleText={Number(averageSales).toFixed(1)}
              bottomText={"Månadens snitt"}
            />
            <DetailsContainer
              icon={WeeklySalesIcon}
              middleText={volunteerWeeklySales}
              bottomText={"Antal sälj vecka"}
            />
          </div>
          <div style={style.GoalsContainer}>
            <PieChartContainer
              pieChartData={PieChartData[0]}
              pieChartTitle={"Veckomål"}
              inactiveColor={"rgba(217, 217, 217, 1)"}
              inactiveTitle={"Uppnått"}
              activeColor={"rgba(99, 146, 52, 1)"}
              activeTitle={"Ouppnått"}
            />
            <PieChartContainer
              pieChartData={PieChartData[1]}
              pieChartTitle={"Månadsmål"}
              inactiveColor={"rgba(217, 217, 217, 1)"}
              inactiveTitle={"Uppnått"}
              activeColor={"rgba(62, 131, 170, 1)"}
              activeTitle={"Ouppnått"}
            />
          </div>
          <div style={style.LeaderboardCityContainer}>
            <LeaderboardCitiesContainer />
          </div>
          <div style={style.LeaderboardVolunteerContainer}>
            <LeaderboardboardVolunteersContainer />
          </div>
        </div>
      </div>
    </VersionDependantStyles>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetCharity: resetCharity,
      clearCustomer: clearCustomer,
      getCustomer: getCustomer,
      sendDeal: sendDeal,
      clearDeal: clearDeal,
      getKPI: getKPI,
      clearKPI: clearKPI,
    },
    dispatch
  );
};
const style = {
  DetailsContainer: {
    display: "flex",
    marginTop: "100px",
  },
  LeaderboardCityContainer: {
    display: "flex",
    flexDirection: "column",
    height: "650px",
    alignItems: "center",
    marginTop: "80px",
  },
  GoalsContainer: {
    display: "flex",
    height: "650",
    marginTop: "50px",
  },
  SubGoalsContainer: {
    margin: "0 50px",
  },
  GoalsTitleContainer: {
    flex: 3,
    marginBottom: "40px",
  },
  GoalsTitleText: {
    fontFamily: "Inter",
    fontSize: "28px",
    textAlign: "center",
  },
  LegendsContainer: {},
  LeaderboardVolunteerContainer: {
    display: "flex",
    height: "1200px",
  },
  TextLeaderboardCity: {
    width: "250px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "28px",
    textAlign: "center",
    margin: "unset",
  },
};
export default connect(mapStateToProps, matchDispatchToProps)(DashboardPage);
