import { ofType } from 'redux-observable';
import { map, delay, mergeMap, catchError } from 'rxjs/operators';
import {
    setAdminUser,
    setAdminUserError,
    LOGIN_ADMIN
} from '../actions/userActions'
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { url } from '../config'

const loginAdminEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOGIN_ADMIN),
    delay(1000),
    mergeMap((action) =>
    ajax({
        url: `${url}/auth/local`,
        body: action.payload,
        method: 'POST',
      }).pipe(
        map((response) => setAdminUser(response.response)),
        catchError((error) => {
          return of(setAdminUserError(error));
        })
      )
    )
  );

export { loginAdminEpic };