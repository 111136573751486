// Compares the time of today and the time when the choice of charity was done
// Returns true if choice was made today
// Returns true if choice was not made today

export const checkValidity = (charity, slug) => {
  if (!charity) return false;

  if (slug && charity.slug !== slug) return false;

  const today = new Date();
  const charityRegistrationtime = new Date(charity.time);
  const sameYear =
    today.getFullYear() === charityRegistrationtime.getFullYear();
  const sameMonth = today.getMonth() === charityRegistrationtime.getMonth();
  const sameDay = today.getDate() === charityRegistrationtime.getDate();
  return sameYear && sameMonth && sameDay;
};

export default function getCharity(slug) {
  const token = localStorage.getItem("jwt");
  const charity = JSON.parse(localStorage.getItem("charity"));

  if (!token || !checkValidity(charity, slug)) {
    return null;
  }
  return charity;
}
