import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { kpiSelectors } from '../../redux/reducers/kpiReducer';
import { HorizontalBarChart } from '../charts/HorizontalBarChart';

const LeaderboardboardVolunteersContainer = () => {
	const [volunteers, setVolunteers] = useState([]);
	const [sales, setSales] = useState([]);
	const salesByVolunteer = useSelector(kpiSelectors.salesByVolunteer);

	useEffect(() => {
		if (salesByVolunteer.length > 0) {
			const volunteersArray = salesByVolunteer.map(
				(item) => item.volunteerName,
			);
			const salesArray = salesByVolunteer.map((item) => item.count);
			setVolunteers(volunteersArray);
			setSales(salesArray);
		}
	}, [salesByVolunteer]);

	return (
		<div style={style.Wrapper}>
			<div style={style.TitleContainer}>
				<p style={style.Title}>Leaderboard</p>
			</div>
			<HorizontalBarChart data={salesByVolunteer} />
		</div>
	);
};

const style = {
	Wrapper: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'center',
	},
	TitleContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '10px',
	},
	Title: {
		fontFamily: 'Inter',
		fontSize: '28px',
	},
};

export default LeaderboardboardVolunteersContainer;
