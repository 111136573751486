import { useEffect } from "react";
import { useLocation } from "@reach/router";
import { useState } from "react";
import getCharity from "../../getCharity";
import useFavicon from "../../hooks/useFavicon";
import { url } from "../../redux/config";

export default function CharityStyles({ children }) {
  const [potentialCharity, setPotentialCharity] = useState(null);
  const location = useLocation();
  const hasLogoUrl = !!potentialCharity?.logo?.url;
  useFavicon(hasLogoUrl ? `${url}${potentialCharity?.logo?.url}` : "");

  useEffect(() => {
    const charity = getCharity();
    setPotentialCharity(charity);
  }, [location]);

  return <div style={styles(potentialCharity)}>{children}</div>;
}

const styles = (charity) => ({
  width: "inherit",
  display: "inherit",
  justifyContent: "inherit",
  backgroundColor: charity?.background_color ?? null,
  color: charity?.font_color ?? null,
});
