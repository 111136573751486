import Button from "../../../components/buttons/optionsButton";

export default function StepOne({
  checkRedBorder,
  setAdress,
  setCity,
  setFamilyName,
  setSurname,
  setZipCode,
  surname,
  familyName,
  adress,
  zipCode,
  city,
}) {
  return (
    <div className={"InputContainer"}>
      <p className={"InputTitle"}>Förnamn</p>
      <input
        className={"TextInput"}
        style={checkRedBorder(surname)}
        type="text"
        disabled
        value={surname}
        onChange={(event) => setSurname(event.target.value)}
      />
      <p className={"InputTitle"}>Efternamn</p>
      <input
        className={"TextInput"}
        style={checkRedBorder(familyName)}
        type="text"
        disabled
        value={familyName}
        onChange={(event) => setFamilyName(event.target.value)}
      />
      <p className={"InputTitle"}>Adress</p>
      <input
        className={"TextInput"}
        style={checkRedBorder(adress)}
        type="text"
        disabled
        value={adress}
        onChange={(event) => setAdress(event.target.value)}
      />
      <p className={"InputTitle"}>Postnummer</p>
      <input
        className={"TextInput"}
        style={checkRedBorder(zipCode)}
        type="text"
        disabled
        value={zipCode}
        onChange={(event) => setZipCode(event.target.value)}
      />
      <p className={"InputTitle"}>Stad</p>
      <input
        className={"TextInput"}
        style={checkRedBorder(city)}
        type="text"
        disabled
        value={city}
        onChange={(event) => setCity(event.target.value)}
      />
    </div>
  );
}
