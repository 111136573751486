import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { clearDeal, sendDeal } from "../../redux/actions/dealActions";
import { kpiSelectors } from "../../redux/reducers/kpiReducer";
import { ajax } from "rxjs/ajax";
import { throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { url } from "../../redux/config";
import { resetCharity } from "../../redux/actions/charityActions";
import {
  getCustomer,
  clearCustomer,
} from "../../redux/actions/customerActions";
import { clearKPI, getKPI } from "../../redux/actions/kpiActions";
import getCharity from "../../getCharity";
import VersionDependantStyles from "../../components/containers/VersionDependantStyles";
import NavBar from "../../components/general/NavBar";

const StatusPage = (props) => {
  const token = localStorage.getItem("jwt");
  const [sales, setSales] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const volunteerName = useSelector(kpiSelectors.volunteerName);
  const charity = getCharity(props.slug);

  useEffect(() => {
    if (!token) {
      return;
    }
    const jwt = `Bearer ${token}`;
    const volunteerId = localStorage.getItem("volunteerId") || 0;
    const payload = {
      Authorization: jwt,
      "x-volunteer-id": volunteerId,
      "charity-id": charity.id,
    };
    setIsLoading(true);

    props.getKPI(payload);

    ajax({
      method: "GET",
      url: `${url}/sales`,
      headers: payload,
    })
      .pipe(
        tap((res) => {
          setIsLoading(false);
          setError(false);
          const sales = res.response.sales;
          const sortedSales = sales.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB - dateA;
          });
          setSales(sortedSales);
        }),
        catchError(() => {
          setError(true);
          return throwError();
        })
      )
      .subscribe();
  }, []);

  const timeFormatter = new Intl.DateTimeFormat("default", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const dateFormatter = new Intl.DateTimeFormat("default", {
    day: "numeric",
    month: "short",
  });

  if (isLoading) {
    return (
      <VersionDependantStyles newVersion={true}>
        <div className="Wrapper">
          <NavBar
            navigate={props.navigate}
            clearCustomer={props.clearCustomer}
            resetCharity={props.resetCharity}
            clearKPI={props.clearKPI}
            slug={props.slug}
            volunteerName={volunteerName}
            chosenTab="status"
          />

          <div className="Container">
            <h1>Laddar...</h1>
          </div>
        </div>
      </VersionDependantStyles>
    );
  }

  if (error) {
    return (
      <div className="Wrapper">
        <NavBar
          navigate={props.navigate}
          clearCustomer={props.clearCustomer}
          resetCharity={props.resetCharity}
          clearKPI={props.clearKPI}
          slug={props.slug}
          volunteerName={volunteerName}
          chosenTab="status"
        />

        <div className="Container">
          <h1>Oops... Något gick fel.</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="Wrapper">
      <NavBar
        navigate={props.navigate}
        clearCustomer={props.clearCustomer}
        resetCharity={props.resetCharity}
        clearKPI={props.clearKPI}
        slug={props.slug}
        volunteerName={volunteerName}
        chosenTab="status"
      />

      <div className="Container">
        <h1>Min Status</h1>

        <table className="StatusTable">
          <th>Tid (UTC)</th>
          <th>Datum</th>
          <th>Summa</th>
          {sales.map((sale) => {
            return (
              <tr>
                <td>
                  <p key={sale.id}>
                    {timeFormatter.format(new Date(sale.saleDateTime))}
                  </p>
                </td>
                <td>
                  <p key={sale.id}>
                    {dateFormatter.format(new Date(sale.saleDateTime))}
                  </p>
                </td>
                <td>
                  <p>{sale.amount ? `${sale.amount} kr` : "Okänd mängd"}</p>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetCharity: resetCharity,
      clearCustomer: clearCustomer,
      getCustomer: getCustomer,
      sendDeal: sendDeal,
      clearDeal: clearDeal,
      clearKPI: clearKPI,
      getKPI: getKPI,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(StatusPage);
