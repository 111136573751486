import { ofType } from "redux-observable";
import { map, mergeMap, catchError, delay } from "rxjs/operators";
import {
  setCharities,
  setCharitiesError,
  GET_CHARITIES,
} from "../actions/charityActions";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";

const getCharitiesEpic = (action$, state$) =>
  action$.pipe(
    ofType(GET_CHARITIES),
    delay(1000),
    mergeMap((action) =>
      ajax({
        url: "https://sign-assently.herokuapp.com/getCharities",
        method: "GET",
      }).pipe(
        map((response) => {
          setCharities(response.response);
        }),
        catchError((error) => {
          return of(setCharitiesError());
        })
      )
    )
  );

export { getCharitiesEpic };
