import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
	},
	scales: {
		x: {
			grid: {
				color: 'transparent',
				borderColor: '#686868',
			},
			ticks: {
				color: '#686868',
				font: {
					weight: '400',
					size: 16,
					fontFamily: 'Inter',
				},
			},
		},
		y: {
			grid: {
				color: '#686868',
				borderColor: '#686868',
			},
			ticks: {
				color: '#686868',
				borderWidth: 10,
				font: {
					weight: 'bold',
				},
			},
		},
	},
	elements: {
		bar: {
			borderWidth: 0,
		},
	},
};

const generatePastelColors = (numColors) => {
	const colors = [];
	const baseHue = Math.floor(Math.random() * 360);
	const saturation = 70;
	const lightness = 80;

	for (let i = 0; i < numColors; i++) {
		const hue = (baseHue + i * 60) % 360;
		const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
		colors.push(color);
	}

	return colors;
};

export const VerticalBarChart = (props) => {
	const pastelColors = generatePastelColors(props.cities.length);

	const data = {
		labels: props.cities,
		datasets: [
			{
				label: 'Dataset 1',
				data: props.sales,
				backgroundColor: pastelColors,
			},
		],
	};
	return <Bar options={options} data={data} salesData={props.salesData} />;
};
