import React from 'react';
import { Logo } from '../../ui/assets/icons';

const Header = () => {
	return (
		<div style={style.Wrapper}>
			<div style={style.leftContainer}>
				<img src={Logo} alt={'icon'} style={style.icon} />
			</div>
			<div style={style.middleContainer}>
				<p style={style.middleText}>Dashboard</p>
			</div>
			<div style={style.rightContainer}></div>
		</div>
	);
};

const style = {
	Wrapper: {
		display: 'flex',
		backgroundColor: '#252525',
	},
	leftContainer: { display: 'flex', flex: 3 },
	middleContainer: {
		display: 'flex',
		flex: 3,
		justifyContent: 'center',
	},
	rightContainer: { display: 'flex', flex: 3 },
	middleText: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontSize: '64px',
		color: '#FFF',
		margin: 'unset',
	},
	icon: { width: '215px', height: '102px' },
};
export default Header;
