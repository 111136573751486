import React from "react";
import { Logo } from "../../../ui/assets/icons";
import getCharity from "../../../getCharity";
import { url } from "../../../redux/config";

const Header = () => {
  const charity = getCharity();

  return (
    <div style={style.Wrapper(charity)} className="Header">
      <div style={style.leftContainer}>
        <img
          src={charity?.logo?.url ? `${url}${charity?.logo?.url}` : Logo}
          alt={"icon"}
          style={style.icon}
        />
      </div>
      <div style={style.middleContainer}>
        <p style={style.middleText}>Dashboard</p>
      </div>
      <div style={style.rightContainer}></div>
    </div>
  );
};

const style = {
  Wrapper: (charity) => ({
    backgroundColor: charity?.background_color
      ? charity.background_color
      : "#fff",
    color: charity?.font_color ? charity.font_color : null,
    padding: "1rem",
  }),
  leftContainer: { display: "flex", flex: 3 },
  middleContainer: {
    display: "flex",
    flex: 3,
    justifyContent: "center",
  },
  rightContainer: { display: "flex", flex: 3 },
  middleText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "64px",
    margin: "unset",
  },
  icon: { height: "102px", marginLeft: "30px" },
};
export default Header;
