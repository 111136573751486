const { SEND_DEAL, SET_DEAL, SET_DEAL_ERROR, CLEAR_DEAL } = require("../actions/dealActions")

const initialState = {
  deal: null,
  isLoading: false,
  error: false,
}
const dealReducer = (state = initialState, {type, payload}) => {
  switch(type){

    case SEND_DEAL:
        return {...state, isLoading: true, error: false}

    case SET_DEAL:
        return {...state, deal: payload, isLoading: true, error: false}

    case SET_DEAL_ERROR:
        return {...state, isLoading: false, error: true}

    case CLEAR_DEAL:
      return {...state, deal: null, isLoading: false, error: false}

    default:
      return state
  }
}

export const dealSelectors = {
    deal: state => state.deal.deal,
    isLoading: state => state.deal.isLoading,
    error: state => state.deal.error,
}

export default dealReducer
