import React from 'react';

const DetailsContainer = (props) => {
	return (
		<div style={style.wrapper}>
			<div style={style.container}>
				<div style={style.topContainer}>
					<img src={props.icon} alt={'icon'} style={style.icon} />
				</div>
				<div style={style.middleContainer}>
					<p style={style.middleText}>{props.middleText}</p>
				</div>
				<div style={style.bottomContainer}>
					<p style={style.bottomText}>{props.bottomText}</p>
				</div>
			</div>
		</div>
	);
};

const style = {
	wrapper: {
		display: 'flex',
		justifyContent: 'center',
		margin: '0 1.5vw',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		width: '19vw',
		height: '19vw',
		border: "1px solid rgba(0, 0, 0, 0.3)"
	},
	container: {
		display: 'flex',
		flex: '1',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	topContainer: {
		display: 'flex',
		flex: 3,
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'flex-end',
		color: "#686868",
	},
	middleContainer: {
		display: 'flex',
		flex: 2,
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	bottomContainer: {
		display: 'flex',
		flex: 3,
		width: '13vw',
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	icon: { width: '3.9vw', height: '3.9vw' },
	middleText: {
		fontFamily: 'Inter-bold',
		fontStyle: 'normal',
		fontSize: '2.8vw',
		lineHeight: '103%',
		textAlign: 'center',
		color: '#686868',
		margin: 'unset',
	},
	bottomText: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontSize: '1.6vw',
		lineHeight: '103%',
		textAlign: 'center',
		color: '#686868',
		margin: 'unset',
		textTransform: 'uppercase',
	},
};

export default DetailsContainer;
