import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { customerSelectors } from "../redux/reducers/customerReducer";
import { dealSelectors } from "../redux/reducers/dealReducer";
import { userSelectors } from "../redux/reducers/userReducer";
import { resetCharity } from "../redux/actions/charityActions";
import { getCustomer, clearCustomer } from "../redux/actions/customerActions";
import { sendDeal, clearDeal } from "../redux/actions/dealActions";
import Button from "../components/buttons/optionsButton";
import { url } from "../redux/config";
import DetailsContainer from "../components/containers/DetailsContainer/DetailsContainer";
import {
  Calendar30DaysIcon,
  MonthlyAverageIcon,
  WeeklySalesIcon,
} from "../ui/assets/icons";
import { clearKPI, getKPI } from "../redux/actions/kpiActions";
import { kpiSelectors } from "../redux/reducers/kpiReducer";
import VersionDependantStyles from "../components/containers/VersionDependantStyles";
import { checkValidity } from "../getCharity";

function EnterDetails(props) {
  const [ssid, setSsid] = useState("");

  const [ssidError, setSsidError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [charity, setCharity] = useState(null);

  const [surname, setSurname] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [adress, setAdress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [adminId, setAdminId] = useState("");
  const [customerDbReferenceId, setCustomerDbReferenceId] = useState(null);
  const [fillError, setFillError] = useState(false);
  const customer = useSelector(customerSelectors.customer);
  const customerLoading = useSelector(customerSelectors.isLoading);
  const customerError = useSelector(customerSelectors.error);
  const deal = useSelector(dealSelectors.deal);
  const dealLoading = useSelector(dealSelectors.isLoading);
  const dealError = useSelector(dealSelectors.error);
  const volunteerMonthlySales = useSelector(kpiSelectors.volunteerMonthlySales);
  const volunteerWeeklySales = useSelector(kpiSelectors.volunteerWeeklySales);
  const averageSales = useSelector(kpiSelectors.averageSales);

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const charity = JSON.parse(localStorage.getItem("charity"));
    // TODO check validity another way?
    // TODO fetch user data with jwt
    if (!token || !checkValidity(charity)) {
      props.navigate("/");
    } else {
      setCharity(charity);
      const jwt = `Bearer ${localStorage.getItem("jwt")}`;
      const volunteerId = localStorage.getItem("volunteerId") || 0;
      const payload = {
        Authorization: jwt,
        "x-volunteer-id": volunteerId,
      };
      props.getKPI(payload);
    }
  }, []);

  useEffect(() => {
    if (customer) {
      setSurname(customer.surname);
      setFamilyName(customer.familyName);
      setAdress(customer.adress);
      setZipCode(customer.zipCode);
      setCity(customer.city);
      setCustomerDbReferenceId(customer.customerDbReferenceId);
    }
  }, [customer]);

  // Redirect after deal created in assently
  // TODO change from agentUrl
  useEffect(() => {
    if (deal) {
      const url = deal.Parties[0].PartyUrl;
      window.location = url;
    }
  }, [deal]);

  const checkSSIDFormat = () => {
    const regex = new RegExp("^([0-9]{6}|[0-9]{8})[-]?[0-9]{4}$");
    const regexCheck = regex.test(ssid);
    setSsidError(!regexCheck);
    return regexCheck;
  };

  const checkEmailFormat = () => {
    const regex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    const regexCheck = regex.test(email);
    setEmailError(!regexCheck);
    return regexCheck;
  };

  const checkAllFilled = () => {
    const fillStatus =
      ssid !== "" &&
      surname !== "" &&
      familyName !== "" &&
      email !== "" &&
      adminId !== "";
    setFillError(!fillStatus);
    return fillStatus;
  };

  const fetchPersonalDetails = () => {
    if (checkSSIDFormat()) {
      const jwt = localStorage.getItem("jwt");
      props.getCustomer(ssid, jwt);
      setSsidError(false);
    }
  };

  const sendDeal = () => {
    if (checkAllFilled() && checkSSIDFormat() && checkEmailFormat()) {
      const jwt = localStorage.getItem("jwt");
      const charity = JSON.parse(localStorage.getItem("charity"));
      props.sendDeal(
        {
          ssid: ssid,
          surname: surname,
          familyName: familyName,
          email: email,
          adress: adress,
          zipCode: zipCode,
          city: city,
          sellerName: charity.name,
          templateId: charity.templateId,
          sellerId: adminId,
          sellerEmail: charity.email,
          customerDbReferenceId,
          redirectUrl: window.location.href.replace("details", "done"),
        },
        jwt
      );
      setFillError(false);
      setSsidError(false);
      setEmailError(false);
    }
  };

  const goBack = () => {
    props.clearCustomer();
    props.resetCharity();
    localStorage.removeItem("jwt");
    localStorage.removeItem("contract");
    localStorage.removeItem("charity");
    localStorage.removeItem("volunteerId");
    props.clearKPI();
    props.navigate("/");
  };
  const goToDashboard = () => {
    props.navigate("/dashboard");
  };

  const startOverDeal = () => {
    setSsidError(false);
    setFillError(false);
    props.clearDeal();
  };

  const toAssently = () => {
    setSsidError(false);
    setFillError(false);
    props.clearDeal();

    window.location = charity.manualEntryUrl;
  };

  const startOverCustomerFetch = () => {
    setSsidError(false);
    setFillError(false);
    props.clearCustomer();
  };

  const checkRedBorder = (text) => {
    if (text === "" && fillError) {
      return { borderColor: "red" };
    } else {
      return {};
    }
  };

  if (customerError) {
    return (
      <div className="Wrapper">
        <div className="Container">
          <p>Något gick fel...</p>
          <Button onClick={() => fetchPersonalDetails()}>Försök igen</Button>
          <Button onClick={() => startOverCustomerFetch()}>Gå tillbaka</Button>
          <Button onClick={() => toAssently()}>Gå till Assently</Button>
        </div>
      </div>
    );
  }

  if (customerLoading) {
    return (
      <div className="Wrapper">
        <div className="Container">
          <p>Hämtar uppgifter om användaren...</p>
        </div>
      </div>
    );
  }

  if (dealError) {
    return (
      <VersionDependantStyles newVersion={false}>
        <div className="Wrapper">
          <div className="Container">
            <p>Något gick fel...</p>
            <Button onClick={() => sendDeal()}>Försök igen</Button>
            <Button onClick={() => startOverDeal()}>Gå tillbaka</Button>
            <Button onClick={() => toAssently()}>Gå till Assently</Button>
          </div>
        </div>
      </VersionDependantStyles>
    );
  }

  if (dealLoading) {
    return (
      <VersionDependantStyles newVersion={false}>
        <div className="Wrapper">
          <div className="Container">
            <p>Skickar avtal...</p>
          </div>
        </div>
      </VersionDependantStyles>
    );
  }

  return (
    <VersionDependantStyles newVersion={false}>
      <div className="Wrapper">
        <div className={"TopBar"}>
          <p className={"BackButton"} onClick={() => goBack()}>
            Logga ut
          </p>

          <p className={"DashboardButton"} onClick={() => goToDashboard()}>
            Dashboard
          </p>
        </div>

        <div className="Container">
          {charity && (
            <div className={"CharityInfo"}>
              {charity?.img?.url ? (
                <img
                  className={"CharityLogo"}
                  src={`${url}${charity.img.url}`}
                  alt="Charity logo"
                />
              ) : (
                <p>{charity.name}</p>
              )}
            </div>
          )}
          <div className={"SSIDbox"}>
            {ssidError && (
              <p className={"ErrorText"}>Fel format på personnummer</p>
            )}
            <p className={"InputTitle"}>Personnummer</p>
            <input
              className="TextInput"
              type="text"
              value={ssid}
              onChange={(event) => setSsid(event.target.value)}
            />
            <Button onClick={() => fetchPersonalDetails()}>
              Hämta adressuppgifter
            </Button>
          </div>
          {customer && (
            <div className={"InputContainer"}>
              <p className={"InputTitle"}>Förnamn</p>
              <input
                className={"TextInput"}
                style={checkRedBorder(surname)}
                type="text"
                value={surname}
                onChange={(event) => setSurname(event.target.value)}
              />
              <p className={"InputTitle"}>Efternamn</p>
              <input
                className={"TextInput"}
                style={checkRedBorder(familyName)}
                type="text"
                value={familyName}
                onChange={(event) => setFamilyName(event.target.value)}
              />
              <p className={"InputTitle"}>Adress</p>
              <input
                className={"TextInput"}
                style={checkRedBorder(adress)}
                type="text"
                value={adress}
                onChange={(event) => setAdress(event.target.value)}
              />
              <p className={"InputTitle"}>Postnummer</p>
              <input
                className={"TextInput"}
                style={checkRedBorder(zipCode)}
                type="text"
                value={zipCode}
                onChange={(event) => setZipCode(event.target.value)}
              />
              <p className={"InputTitle"}>Stad</p>
              <input
                className={"TextInput"}
                style={checkRedBorder(city)}
                type="text"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <p className={"InputTitle"}>Epost</p>
              {emailError && <p className={"ErrorText"}>Fel format på email</p>}
              <input
                className={"TextInput"}
                style={checkRedBorder(email)}
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <p className={"InputTitle"}>Volontärnummer</p>
              <input
                className={"TextInput"}
                style={checkRedBorder(adminId)}
                type="text"
                value={adminId}
                onChange={(event) => setAdminId(event.target.value)}
              />
              {fillError && <p className={"ErrorText"}>Fyll i alla fält</p>}
              <Button onClick={() => sendDeal()}>Gå vidare</Button>
            </div>
          )}
        </div>
      </div>
    </VersionDependantStyles>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetCharity: resetCharity,
      clearCustomer: clearCustomer,
      getCustomer: getCustomer,
      sendDeal: sendDeal,
      clearDeal: clearDeal,
      clearKPI: clearKPI,
      getKPI: getKPI,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(EnterDetails);
