const {
  RESET_CHARITY,
  GET_CHARITIES,
  SELECT_CHARITY,
  SET_CHARITIES,
  SET_CHARITIES_ERROR,
} = require("../actions/charityActions");

const initialState = {
  selectedCharity: "",
  charities: [],
  isLoading: false,
  error: false,
};
const charityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CHARITIES:
      return { ...state, charities: [], isLoading: true, error: false };

    case SELECT_CHARITY:
      return { ...state, selectedCharity: payload };

    case RESET_CHARITY:
      return { ...state, selectedCharity: "" };

    case SET_CHARITIES:
      return { ...state, charities: payload, isLoading: false, error: false };

    case SET_CHARITIES_ERROR:
      return { ...state, charities: [], isLoading: false, error: true };

    default:
      return state;
  }
};

export const charitySelector = {
  selectedCharity: (state) => state.charity.selectedCharity,
  charities: (state) => state.charity.charities,
  isLoading: (state) => state.charity.isLoading,
  error: (state) => state.charity.error,
};

export default charityReducer;
