import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { resetCharity } from "../../redux/actions/charityActions";
import { clearCustomer } from "../../redux/actions/customerActions";
import { clearDeal } from "../../redux/actions/dealActions";
import Button from "../../components/buttons/optionsButton";
import CharityStyles from "../../components/containers/CharityStyles";
import VersionDependantStyles from "../../components/containers/VersionDependantStyles";

function FinishedScreen(props) {
  const goBack = () => {
    props.clearCustomer();
    props.resetCharity();
    props.clearDeal();
    props.navigate(`/${props.slug}/details`);
  };

  return (
    <VersionDependantStyles newVersion={true}>
      <div className="App">
        <div className="Container">
          <p>Klart!</p>
          <Button onClick={() => goBack()}>Nytt avtal</Button>
        </div>
      </div>
    </VersionDependantStyles>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearCustomer: clearCustomer,
      resetCharity: resetCharity,
      clearDeal: clearDeal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(FinishedScreen);
