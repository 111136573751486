const SEND_DEAL = "SEND_DEAL"
const sendDeal = (deal, token) => {
  return {
    type: SEND_DEAL,
    payload: {deal: deal, token: token}
  }
}
export {sendDeal, SEND_DEAL}

const SET_DEAL = "SET_DEAL"
const setDeal = (deal) => {
  return {
    type: SET_DEAL,
    payload: deal
  }
}
export {setDeal, SET_DEAL}

const SET_DEAL_ERROR = "SET_DEAL_ERROR"
const setDealError = () => {
  return {
    type: SET_DEAL_ERROR
  }
}
export {setDealError, SET_DEAL_ERROR}

const CLEAR_DEAL = "CLEAR_DEAL"
const clearDeal = () => {
  return {
    type: CLEAR_DEAL
  }
}
export {clearDeal, CLEAR_DEAL}