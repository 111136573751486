import React, { useState, useEffect } from "react";
import logo from "../logo.svg";
import { Link } from "@reach/router";
import { bindActionCreators } from "redux";
import { useDispatch, connect, useSelector } from "react-redux";
import { charitySelector } from "../redux/reducers/charityReducer";
import { userSelectors } from "../redux/reducers/userReducer";
import { selectCharity, getCharities } from "../redux/actions/charityActions";
import { clearCustomer } from "../redux/actions/customerActions";
import { clearDeal } from "../redux/actions/dealActions";
import { loginAdmin, clearAdminUser } from "../redux/actions/userActions";
import Button from "../components/buttons/optionsButton";
import { kpiSelectors } from "../redux/reducers/kpiReducer";
import VersionDependantStyles from "../components/containers/VersionDependantStyles";
import { checkValidity } from "../getCharity";
import { ajax } from "rxjs/ajax";
import { mergeMap, tap, catchError } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { url } from "../redux/config";

const SelectCharity = (props) => {
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [volunteerId, setVolunteerId] = useState("");
  const [fillError, setFillError] = useState(false);

  const adminUser = useSelector(userSelectors.adminUser);
  const isLoading = useSelector(userSelectors.isLoading);
  const error = useSelector(userSelectors.error);
  const isLoadingKPI = useSelector(kpiSelectors.isLoading);
  const [isDisabled, setIsDisabled] = useState(false);

  // Fetch options to see if legacy login form should be disabled or not
  useEffect(() => {
    props.clearCustomer();
    props.clearDeal();

    ajax({
      method: "GET",
      url: `${url}/options`,
    })
      .pipe(
        tap(({ response }) => {
          setIsDisabled(response.disableLegacyForm);
        }),
        catchError(() => {
          setIsDisabled(false);
          return throwError();
        })
      )
      .subscribe();
  });

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const charity = JSON.parse(localStorage.getItem("charity"));
    if (token && checkValidity(charity)) {
      props.navigate("/details");
    }
  }, [adminUser, props]);

  const checkAllFilled = () => {
    const fillStatus = userName !== "" && password !== "" && volunteerId !== "";
    setFillError(!fillStatus);
    return fillStatus;
  };

  const checkRedBorder = (text) => {
    if (text === "" && fillError) {
      return { borderColor: "red" };
    } else {
      return {};
    }
  };

  const logIn = () => {
    if (checkAllFilled()) {
      props.loginAdmin({
        identifier: userName,
        password: password,
      });
      setFillError(false);
    }
  };

  const resetLogin = () => {
    props.clearAdminUser();
    setPassword("");
  };

  if (error) {
    return (
      <div className="Wrapper">
        <div className="Container">
          <p>Något gick fel...</p>
          <Button onClick={() => logIn()}>Försök igen</Button>
          <Button onClick={() => resetLogin()}>Tillbaka</Button>
        </div>
      </div>
    );
  }

  if (isLoading && isLoadingKPI) {
    return (
      <div className="Wrapper">
        <div className="Container">
          <p>Loggar in...</p>
        </div>
      </div>
    );
  }

  return (
    <VersionDependantStyles newVersion={false}>
      <div className="Wrapper">
        <div className="Container">
          <div className={"InputContainer"}>
            {isDisabled && (
              <p className={"ErrorText"}>
                Detta fält och inloggningar via den är för tillfället
                inaktiverat.
              </p>
            )}
            <p className={"InputTitle"}>Användarnamn</p>
            <input
              disabled={isDisabled}
              className={"TextInput"}
              style={checkRedBorder(userName)}
              type="text"
              value={userName}
              onChange={(event) => setUsername(event.target.value)}
            />
            <p className={"InputTitle"}>Lösenord</p>
            <input
              disabled={isDisabled}
              className={"TextInput"}
              style={checkRedBorder(password)}
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <p className={"InputTitle"}>Volontär ID</p>
            <input
              disabled={isDisabled}
              className={"TextInput"}
              type="text"
              style={checkRedBorder(volunteerId)}
              value={volunteerId}
              onChange={(event) => setVolunteerId(event.target.value.trim())}
            />
            {fillError && (
              <p className={"ErrorText"}>
                Du måste fylla i användarnamn, lösenord och ditt volontär id.
              </p>
            )}
            <Button
              disabled={isDisabled}
              onClick={() => {
                localStorage.setItem("volunteerId", volunteerId);
                logIn();
              }}
            >
              Logga in
            </Button>
          </div>
        </div>
      </div>
    </VersionDependantStyles>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selectCharity: selectCharity,
      getCharities: getCharities,
      clearCustomer: clearCustomer,
      clearDeal: clearDeal,
      loginAdmin: loginAdmin,
      clearAdminUser: clearAdminUser,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(SelectCharity);
