const SELECT_CHARITY = "SELECT_CHARITY";
const selectCharity = (charity) => {
  return {
    type: SELECT_CHARITY,
    payload: charity,
  };
};
export { selectCharity, SELECT_CHARITY };

const RESET_CHARITY = "RESET_CHARITY";
const resetCharity = () => {
  return {
    type: RESET_CHARITY,
  };
};
export { resetCharity, RESET_CHARITY };

const GET_CHARITIES = "GET_CHARITIES";
const getCharities = () => {
  return {
    type: GET_CHARITIES,
  };
};
export { getCharities, GET_CHARITIES };

const SET_CHARITIES = "SET_CHARITIES";
const setCharities = (charities) => {
  return {
    type: SET_CHARITIES,
    payload: charities,
  };
};
export { setCharities, SET_CHARITIES };

const SET_CHARITIES_ERROR = "SET_CHARITIES_ERROR";
const setCharitiesError = () => {
  return {
    type: SET_CHARITIES_ERROR,
  };
};
export { setCharitiesError, SET_CHARITIES_ERROR };
