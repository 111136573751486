import React from 'react';
import { PieChart } from '../charts/PieChart';

const PieChartContainer = (props) => {
	return (
		<div style={style.Wrapper}>
			<div style={style.GoalsTitleContainer}>
				<div style={style.GoalsTitleText}>{props.pieChartTitle}</div>
			</div>
			<div style={style.PieContainer}>
				<PieChart {...props.pieChartData} />
			</div>
			<div style={style.GoalsTitleContainer}>
				<div style={style.LegendsContainer}>
					<div style={style.Legend}>
						<div
							style={{
								...style.LegendsIcon,
								backgroundColor: `${props.activeColor}`,
							}}
						/>
						<div style={style.LegendsText}>
							{props.inactiveTitle}
						</div>
					</div>
					<div style={style.Legend}>
						<div
							style={{
								...style.LegendsIcon,
								backgroundColor: `${props.inactiveColor}`,
							}}
						/>
						<div style={style.LegendsText}>{props.activeTitle}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const style = {
	Wrapper: {
		display: 'flex',
		flexDirection: 'column',
		margin: '0 1%',
		alignItems: 'center',
	},
	GoalsTitleContainer: {
		flex: 3,
		marginBottom: '40px',
		flexDirection: 'column',
	},
	PieContainer: {
		width: '250px',
		height: '250px',
	},
	GoalsTitleText: {
		fontFamily: 'Inter',
		fontSize: '28px',
		textAlign: 'center',
	},
	LegendsContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	Legend: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0 52px',
	},
	LegendsIcon: {
		width: '105px',
		height: '31px',
		background: '#639234',
	},
	LegendsText: {
		fontFamily: 'Inter',
		fontSize: '16px',
		textAlign: 'center',
	},
};
export default PieChartContainer;
