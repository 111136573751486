import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
export const data = {
	labels: ['Purple', 'Orange'],
	datasets: [
		{
			label: '# of Votes',
			data: [12, 19],
			backgroundColor: ['rgba(99, 146, 52, 1)', 'rgba(217, 217, 217, 1)'],
			borderColor: ['rgba(145, 221, 68, 1)', 'rgba(217, 217, 217, 0)'],
			borderWidth: 3,
		},
	],
};
export const PieChart = (props) => {
	return (
		<Pie
			data={{
				labels: props.labels,
				datasets: [
					{
						label: props.label,
						data: props.data,
						backgroundColor: props.backgroundColor,
						borderColor: props.borderColor,
						borderWidth: props.borderWidth,
					},
				],
			}}
			options={{
				plugins: {
					legend: {
						align: 'center',
						position: 'bottom',
						labels: {
							usePointStyle: false,
							font: {
								size: 16,
								family: 'Inter',
							},
							boxWidth: 20,
						},
						display: false,
					},
				},
			}}
		/>
	);
};
