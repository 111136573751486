import { useState, useCallback } from "react";
import CharityStyles from "../../components/containers/CharityStyles";

export default function NavBar({
  navigate,
  clearCustomer,
  resetCharity,
  clearKPI,
  slug,
  volunteerName,
  chosenTab,
}) {
  const goBack = () => {
    clearCustomer();
    resetCharity();
    localStorage.removeItem("jwt");
    localStorage.removeItem("contract");
    localStorage.removeItem("charity");
    localStorage.removeItem("volunteerId");
    clearKPI();
    navigate(`/${slug}`);
  };
  const navigateTo = useCallback(
    (path) => {
      navigate(`/${slug}/${path}`);
    },
    [navigate, slug]
  );
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={"TopBar"}>
      <CharityStyles>
        <div id="mobile">
          <p className={`BackButton`} onClick={() => goBack()}>
            {volunteerName} - Logga ut
          </p>
          <div className={"RightSideNavSection"}>
            <p
              className={`StatusButton`}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Visa mindre" : "Visa mer"}
            </p>
            {expanded && (
              <>
                <p
                  className={`StatusButton ${
                    isChosen(chosenTab, "status") && "ChosenTab"
                  }`}
                  onClick={() => navigateTo("status")}
                >
                  Min status
                </p>
                <p
                  className={`StatusButton ${
                    isChosen(chosenTab, "details") && "ChosenTab"
                  }`}
                  onClick={() => navigateTo("details")}
                >
                  Registrera kund
                </p>
                <p
                  className={`DashboardButton ${
                    isChosen(chosenTab, "dashboard") && "ChosenTab"
                  }`}
                  onClick={() => navigateTo("dashboard")}
                >
                  Dashboard
                </p>
              </>
            )}
          </div>
        </div>
        <div id="desktop">
          <p className={`BackButton`} onClick={() => goBack()}>
            {volunteerName} - Logga ut
          </p>
          <div className={"RightSideNavSection"}>
            <p
              className={`StatusButton ${
                isChosen(chosenTab, "status") && "ChosenTab"
              }`}
              onClick={() => navigateTo("status")}
            >
              Min status
            </p>
            <p
              className={`StatusButton ${
                isChosen(chosenTab, "details") && "ChosenTab"
              }`}
              onClick={() => navigateTo("details")}
            >
              Registrera kund
            </p>
            <p
              className={`DashboardButton ${
                isChosen(chosenTab, "dashboard") && "ChosenTab"
              }`}
              onClick={() => navigateTo("dashboard")}
            >
              Dashboard
            </p>
          </div>
        </div>
      </CharityStyles>
    </div>
  );
}

function isChosen(chosenPath, path) {
  return path === chosenPath;
}
