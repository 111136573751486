import Button from "../../../components/buttons/optionsButton";
import Kontonummer from "../../../bankAccountValidation";

export default function StepTwo({
  email,
  phone,
  bankAccount,
  clearingNumber,
  adminId,
  emailError,
  setEmail,
  setPhone,
  setBankAccount,
  setClearingNumber,
  setAdminId,
  checkRedBorder,
  fillError,
  sendDeal,
}) {
  const bankAccountValid = Kontonummer.valid(
    `${clearingNumber} ${bankAccount}`
  );
  const bankAccountValidOrNotFulfilled =
    bankAccount.replace(/ /g, "").length < 7 || bankAccountValid;

  return (
    <div className={"InputContainer"}>
      <p className={"InputTitle"}>Epost*</p>
      {emailError && <p className={"ErrorText"}>Fel format på email</p>}
      <input
        className={"TextInput"}
        style={checkRedBorder(email)}
        type="text"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <p className={"InputTitle"}>Mobiltelefonnummer*</p>
      <input
        className={"TextInput"}
        style={checkRedBorder(phone)}
        type="text"
        value={phone}
        onChange={(event) => setPhone(event.target.value)}
      />
      <p className={"InputTitle"}>Clearingnummer*</p>
      <input
        className={"TextInput"}
        style={checkRedBorder(clearingNumber)}
        type="text"
        value={clearingNumber}
        onChange={(event) => setClearingNumber(event.target.value)}
      />
      <p className={"InputTitle"}>Bankkonto (exkl clearingnummer)*</p>
      <input
        className={"TextInput"}
        style={checkRedBorder(bankAccount)}
        type="text"
        value={bankAccount}
        onChange={(event) => setBankAccount(event.target.value)}
      />
      {!bankAccountValidOrNotFulfilled && (
        <p style={{ width: "370px" }} className={"ErrorText"}>
          Bankkonto verkar vara ogiltigt. Vänligen dubbelkolla att
          clearingnummer och bankkonto stämmer. Om du är säker att båda är
          korrekta kan du ignorera detta felmeddelande.
        </p>
      )}
      {bankAccountValid && (
        <p style={{ width: "370px" }} className="SuccessText">
          Bankkonto verkar vara giltigt.
        </p>
      )}
      <p className={"InputTitle"}>Volontärnummer*</p>
      <input
        className={"TextInput"}
        style={checkRedBorder(adminId)}
        type="text"
        value={adminId}
        onChange={(event) => setAdminId(event.target.value)}
      />
      {fillError && (
        <p className={"ErrorText"}>Fyll i alla obligatoriska fält</p>
      )}
      <Button onClick={() => sendDeal()}>Skicka avtal</Button>
    </div>
  );
}
